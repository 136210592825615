import { useEffect } from "react";
import { Helmet } from "react-helmet";
import Infocard from "../Components/Infocard";
import styles from "./styles/Datenschutz.module.css";
import { Link } from "react-router-dom";

const datenschutz = {
  svg: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={styles.datenschutz_svg}
      viewBox="0 0 576 512"
    >
      {/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
      <path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384v47l-92.8 37.1c-21.3 8.5-35.2 29.1-35.2 52c0 56.6 18.9 148 94.2 208.3c-9 4.8-19.3 7.6-30.2 7.6H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zm39.1 97.7c5.7-2.3 12.1-2.3 17.8 0l120 48C570 277.4 576 286.2 576 296c0 63.3-25.9 168.8-134.8 214.2c-5.9 2.5-12.6 2.5-18.5 0C313.9 464.8 288 359.3 288 296c0-9.8 6-18.6 15.1-22.3l120-48zM527.4 312L432 273.8V461.7c68.2-33 91.5-99 95.4-149.7z" />
    </svg>
  ),
  title: "Datenschutz",
  text: (
    <>
      <h3 className={styles.ccr_heading}>Datenerhebung und -verwendung:</h3>
      <p>
        Wir verarbeiten Ihre Daten in Übereinstimmung mit den Bestimmungen der
        Datenschutz-Grundverordnung (DSGVO). Diese Datenschutzerklärung
        informiert Sie darüber, wie wir Ihre Daten auf unserer Website
        verarbeiten. Wir erheben und verwenden personenbezogene Daten nur in dem
        Umfang, der zur Erfüllung des Zwecks erforderlich ist, für den die Daten
        bereitgestellt wurden. Bei der Registrierung auf unserer Website werden
        folgende Daten erfasst und gespeichert: E-Mail-Adresse, vollständiger
        Name und ihr angegebens Passwort in verschlüsselter Form. Diese
        Informationen werden ausschließlich für den Zweck der Registrierung und
        zur Bereitstellung der angebotenen Dienste verwendet.
      </p>
      <br />
      <h3 className={styles.ccr_heading}>Rechtsgrundlage der Verarbeitung:</h3>
      <p>
        Die Verarbeitung Ihrer Daten erfolgt auf Grundlage der DSGVO. Durch die
        Registrierung und die Angabe der Daten willigen die Nutzer in die
        Verarbeitung ihrer Daten gemäß dieser Datenschutzerklärung ein.
      </p>
      <br />
      <h3 className={styles.ccr_heading}>Datensicherheit:</h3>
      <p>
        Wir setzen technische und organisatorische Sicherheitsmaßnahmen ein, um
        die gespeicherten personenbezogenen Daten vor unbefugtem Zugriff,
        Missbrauch oder Verlust zu schützen. Die Daten werden in einer sicheren
        Umgebung gespeichert und nur befugten Personen mit speziellen
        Zugriffsrechten zugänglich gemacht.
      </p>
      <br />
      <h3 className={styles.ccr_heading}>
        Verwendung von Google Maps und Cookies:
      </h3>
      <p>
        Auf unserer Website verwenden wir einen eingebetteten Google
        Maps-Service, um Ihnen Standortinformationen bereitzustellen. Bitte
        beachten Sie, dass der Google Maps-Service erst aktiviert wird, sobald
        Sie unsere Cookies akzeptieren. Durch die Aktivierung des Google
        Maps-Service können auch Cookies von Google gesetzt werden, um
        Standortinformationen und andere Daten zu sammeln. Die Verwendung von
        Google Maps unterliegt den Nutzungsbedingungen von Google und der
        Google-Datenschutzerklärung.
      </p>
      <h3 className={styles.ccr_heading}>Zustimmung zu Cookies:</h3>
      <p>
        Beim ersten Besuch unserer Website werden Sie um Ihre Zustimmung zur
        Verwendung von Cookies gebeten. Wenn Sie der Verwendung von Cookies
        zustimmen, wird das Cookie "cookieConsent" gesetzt, um Ihre Zustimmung
        zu speichern. Diese Cookies werden maximal 30 Tage lang gespeichert.
        Mehr Infos über Cookies selbst finden sie auf{" "}
        <a
          className={styles.mail}
          rel="noreferrer"
          target="_blank"
          href="https://www.aboutcookies.org/"
        >
          aboutcookies.org
        </a>
      </p>
      <h3 className={styles.ccr_heading}>Cookie-Verwaltung:</h3>
      <p>
        Die meisten Webbrowser akzeptieren Cookies automatisch. Sie können
        jedoch die Einstellungen Ihres Browsers anpassen, um Cookies abzulehnen
        oder eine Benachrichtigung zu erhalten, wenn Cookies gesendet werden.
        Bitte beachten Sie, dass das Deaktivieren von Cookies die Funktionalität
        unserer Website und des Google Maps-Service beeinträchtigen kann.
      </p>
      <h3 className={styles.ccr_heading}>Weitergabe von Daten an Dritte:</h3>
      <p>
        Die von uns erfassten personenbezogenen Daten werden nicht an Dritte
        weitergegeben, es sei denn, dies ist gesetzlich vorgeschrieben oder zur
        Erfüllung der vertraglichen Verpflichtungen notwendig.
      </p>
      <h3 className={styles.ccr_heading}>Rechte der Nutzer:</h3>
      <p>
        Nutzer haben gemäß der DSGVO das Recht auf Auskunft über die sie
        betreffenden personenbezogenen Daten, sowie auf Berichtigung, Löschung
        oder Einschränkung der Verarbeitung. Sie können außerdem der
        Verarbeitung ihrer Daten widersprechen und haben das Recht auf
        Datenübertragbarkeit. Zur Ausübung dieser Rechte können sich Nutzer
        jederzeit an die im Impressum angegebenen Kontaktdaten wenden.
      </p>
      <h3 className={styles.ccr_heading}>
        Änderungen der Datenschutzerklärung und Cookie-Richtlinie:
      </h3>
      <p>
        Wir behalten uns das Recht vor, diese Datenschutzerklärung und
        Cookie-Richtlinie jederzeit anzupassen. Die jeweils aktuelle Version ist
        auf unserer Website verfügbar.
      </p>
      <br />
      <p>Letzte Aktualisierung: 30.08.2023</p>
    </>
  ),
};

const impressum = {
  svg: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="4.3rem"
      viewBox="0 0 256 512"
    >
      {/*<!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->*/}
      <path d="M64.9 96C67.1 84.4 73.7 76.2 86 70.6c13.8-6.2 34.8-8.9 61.2-4.5c8.8 1.4 36.1 7.1 44.1 9.3c17 4.8 34.7-5.1 39.5-22.2s-5.1-34.7-22.2-39.5c-11.1-3.1-41-9.2-50.9-10.8C123-2.7 88.3-.6 59.7 12.3C29.9 25.8 7.5 50.9 1.6 86.5c-.1 .5-.2 1.1-.2 1.6c-2.2 19.7 .3 37.9 8.1 54.1c7.7 16.1 19.4 28 32 36.9c.6 .5 1.3 .9 2 1.4C22.3 194.2 6.5 215.1 1.7 243c-.1 .6-.2 1.1-.2 1.7c-2.3 19.3 .4 37.1 8.4 53c7.9 15.6 19.8 27 32.3 35.5c22.4 15.2 51.9 24 75.4 31l0 0 3.7 1.1c27.2 8.2 46.9 14.6 59.4 23.8c5.5 4 8.2 7.6 9.5 10.9c1.3 3.2 2.6 8.6 .9 18.1c-1.7 10.1-7.7 18-20.7 23.5c-14 6-35.4 8.5-62 4.4c-12.8-2.1-35.1-9.7-54.1-16.2l0 0c-4.3-1.5-8.5-2.9-12.3-4.2C25.3 420 7.2 429.1 1.6 445.8s3.5 34.9 20.3 40.5c2.6 .8 5.7 1.9 9.2 3.1c18.6 6.3 48.5 16.6 67.3 19.6l0 0 .2 0c34.5 5.4 68.8 3.4 97.2-8.7c29.4-12.6 52.5-36.5 58.5-71.5c3.3-19.3 1.9-37.4-5-53.9c-6.3-15-16.4-26.4-27.6-35.2c16.5-13.9 28.5-33.2 32.6-58.2c3.2-19.8 1.9-38.3-4.8-55.1c-6.7-16.8-17.8-29.4-30.2-39c-22.8-17.6-53.6-27.4-77.7-35l-1.4-.5c-27.4-8.7-47.8-15.3-61.5-25c-6.1-4.4-9.5-8.5-11.4-12.4c-1.8-3.7-3.2-9.3-2.3-18.5zm76.7 208.5c-.2-.1-.4-.1-.6-.2l-1.4-.4c-27.4-8.2-47.9-14.5-61.7-23.8c-6.2-4.2-9.3-7.9-11-11.3c-1.5-3-2.9-7.7-2.1-15.7c1.9-9.7 7.9-17.3 20.5-22.7c14-6 35.4-8.5 62.1-4.3l16.4 2.6c6.3 2.9 11.7 6 16.2 9.5c5.5 4.2 8.4 8.2 10 12.2c1.6 4 2.8 10.4 1.1 20.9c-2.4 14.7-12.8 26.4-37.1 31l-12.4 2.3z" />
    </svg>
  ),
  title: "Impressum",
  text: (
    <>
      <h3 className={styles.ccr_heading}>Cold Creek Ranch</h3>
      <p>
        Ausweger OG <br />
        Kesselmannstraße 18/1 <br />
        5324 Faistenau <br />
        FN: 586235k<br />
        <br />
        Mail:{" "}
        <a className={styles.mail} href="mailto:info@coldcreekranch.at">
          info@coldcreekranch.at
        </a>{" "}
        <br />
        <br />
        Für den Websiteninhalt verantwortlich: Anna Ausweger <br />
        <br />
        Fotos: ColdCreekRanch, David Pöttler
        <br />
      </p>
      <br />
      <h3 className={styles.rechtshinweis_heading}>Rechtlicher Hinweis</h3>
      <p>
        Im Hinblick auf die technischen Eigenschaften des Internet kann keine
        Gewähr für die Authentizität, Richtigkeit und Vollständigkeit der im
        Internet zur Verfügung gestellten Informationen übernommen werden. Es
        wird auch keine Gewähr für die Verfügbarkeit oder den Betrieb der
        gegenständlichen Webseite und ihrer Inhalte übernommen. Jede Haftung für
        unmittelbare, mittelbare oder sonstige Schäden, unabhängig von deren
        Ursachen, die aus der Benutzung oder Nichtverfügbarkeit der Daten und
        Informationen dieser Homepage erwachsen, wird, soweit rechtlich
        zulässig, ausgeschlossen. Unser Angebot enthält Links zu externen
        Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb
        können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für
        die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder
        Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum
        Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
        Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
        erkennbar. Der Inhalt dieser Webseite ist urheberrechtlich geschützt.
        Untersagt ist eine Nutzung ohne Zustimmung des Herausgebers bzw. des
        Urhebers.
        <br />
        <br />
        <span className={styles.kleinklein}>
          <Link to="https://fontawesome.com/license/free">Icons von FontAwsome. Lizenz: CC 4.0</Link>
        </span>
      </p>
    </>
  ),
};

function Impressum() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Impressum und Datenschutz | Cold Creek Ranch</title>
        <meta
          name="description"
          content="Cold Creek Ranch - Datenschutz und Impressum!"
        />
      </Helmet>

      <hr />
      <div className="datenschutz">
        <Infocard info={datenschutz} />
      </div>
      <div className="impressum">
        <Infocard info={impressum} />
      </div>
    </>
  );
}

export default Impressum;
