import React from "react";
import styles from "./styles/Horses.module.css";

function Inforcard({ info }) {
    return (
        <div className={styles.card}>
            <img className={styles.image} src={info.image} alt={info.title} />  
            <h1 className={styles.title}>{info.title}</h1>   
        </div>
    )
}

export default Inforcard;